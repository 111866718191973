<template>
<div class="autoOrder-inputs autoOrder-inputs--orange" :class="this.$route.path=='/admin/menu'?'orange':''" v-if="this.$route.path=='/admin/preparation/assembly'||this.$route.path=='/admin/menu'">
    <div class="inputs-line inputs-line--orange mb-2 pb-1 d-flex">
        <input type="radio" :id="0" name="week" :value="0" :checked="true" @click='setDay(0)'>
        <!-- <label :for="0" v-if="this.$route.path=='/admin/preparation/assembly'">Все заказы</label> -->
       <label :for="0">Все</label>
        <template v-for="(day,index) in week">
            <div :key="index" v-if='checkDay(day)'>
                <input type="radio" :id="day" name="week" :value="day" :key="index" @click='setDay(day)'>
                <label :for="day" :key="index">{{$moment(day).format('ddd, DD.MM')}}</label>
            </div>
        </template>

    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            week: []
        }
    },
    async mounted() {
        await this.$store.dispatch("actionGetDayOfWeek");
        for (let index = 0; index < 7; index++) {
            this.week.push(this.$moment(new Date(new Date().setDate(new Date().getDate() + index))).format("YYYY-MM-DD"))
        }
        console.log(this.week)
        this.$store.commit('SET_DAY_OF_WEEK', 0);
    },

    methods: {
        async setDay(day) {
            this.$store.commit('SET_DAY_OF_WEEK', day);

            this.$store.commit('loader', true);
            await this.$store.dispatch("actionGetGroupAdmin", {
                id: this.$store.state.admin.catGroup,
                date: day
            });
            this.$store.commit('loader', false);

        },
        checkDay(day) {
            var res = this.$store.state.admin.days.find(item => item.date == day)
            if (res == undefined)
                return true;
        }
    },
}
</script>

<style lang="scss" scoped>
.inputs-line {

    input[type="checkbox"],
    input[type="radio"] {
        opacity: 0;
        position: fixed;
        width: 0;
    }

    label {
        display: inline-block;
        background-color: #fff;
        padding: 16px 30px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 109.5%;
        /* or 15px */

        color: #626262;

        border: 1px solid #3F2A2C;
        ;
        margin-bottom: 10px;
        border-radius: 12px;
        margin-right: 10px;
        text-align: center;
        white-space: nowrap;
        // width: 160px;
        cursor: pointer;
        background: #fff;
    }

    // label:hover {
    //     background-color: $orange;
    //     border-color: $orange;
    //     color: #fff;
    // }

    // input[type="checkbox"]:focus+label {
    //     background-color: $orange;
    //     border-color: $orange;
    //     color: #fff;
    // }

    input[type="checkbox"]:checked+label,
    input[type="radio"]:checked+label {
        background-color: #3F2A2C;
        border-color: #3F2A2C;
        color: #fff;
    }

    &--orange {
        label {
            background: #F5F5F5;
            border: none;
            padding-left: 40px;
            padding-right: 40px;
            font-size: 18px;
        }

        input[type="checkbox"]:checked+label,
        input[type="radio"]:checked+label {
            background-color: #3F2A2C;
            border-color: #3F2A2C;
            color: #fff;
            border: none;
        }
    }
}
.orange{
       input[type="checkbox"]:checked+label,
        input[type="radio"]:checked+label {
            background-color: $orange;
            border-color: $orange;
            color: #fff;
            border: none;
        }
}
</style>
